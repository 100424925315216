import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { ListOfSocialNetworks } from "../components/ListOfSocialNetworks"
const linkStyle = { minWidth: "23ch" }

class LinkTreePage extends React.Component<MHPageProps> {
  render() {
    return (
      <>
        <SEO title="Linktree" />
        <div className="oneColumn n-text-centered">
          <h1 className="n-text-centered n-vspacing-large">
            Places to find me
          </h1>

          <ul className="n-spacing-top-small gap-3 flex flex-column">
            <li>
              <Link className="button spacing" to="/" style={linkStyle}>
                My website (you're on it)
              </Link>
            </li>
            <li>
              <a href="/rss.xml" className="button" style={linkStyle}>
                RSS feed
              </a>
            </li>
            <ListOfSocialNetworks itemStyle={linkStyle} />
          </ul>
        </div>
      </>
    )
  }
}

export default LinkTreePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
